
<template>
  <div class="main-content">

    <div class="row">
      <div class="col-md-12">

      </div>
    </div>


  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  }
};
</script>
